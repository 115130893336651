import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

const Chan = () => {
  return (
    <Layout>
      <Head title="Chan Somethingstar" />
      <h3>Chan Somethingstar</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Paris
      </h4>
      <p>
        <OutboundLink href="https://www.instagram.com/chan_somethingstar/">
          Instagram
        </OutboundLink>
      </p>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/3QXEJ4eL70Y"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/nEjDS38mlSc"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <p>
        Xavier Dallet aka Chan Somethingstar, is a visual artist working on
        internet. Dallet was born in Villeneuve sur Lot in the South of France
        in 1980. After spending his childhood in Dakar, he moved to Normandy,
        where he first studied under Jacques Pasquier, his mentor in painting.
        He then pursued further studies at the School of Arts in Caen (1994-97),
        Cherbourg (2001-03) and Le Havre (2004-07). He moved to Paris in 2006
        where he attended the school of photography, Efet (2015).
      </p>
      <p>
        Since childhood, Xavier lived and studied with feelings that mixed
        mysticism with reveries about life in general. This was expressed
        through several art experiences, painting, conceptual objects and
        photographic "ready-mades". In his photographic exploration of
        "ready-mades", with a small toy washing machine (from Paris to Rabat
        from 2006 to 2014), he realized that his objects of study were about his
        presence on internet, for example, an avatar he made in 2008-09. This
        discovery led him to stop painting and progressively, studio arts
        altogether in the sense that since his time at the Efet school of
        photography, internet had become his canvas and the brush, for exploring
        his feelings.
      </p>
      <p>
        Dallet has been involved with the art collectives, Le Baobab and Perfect
        Users for the past four years. He investigates his art mainly through
        "Messenger", where he has ephemeral conversations on two themes : the
        composition of a column and playing with images from popular culture.
        After two years of sharing more than 200,000 images on Baobab, he has
        been working on the examination of phone art with over 700 videos of
        less than a minute. Xavier makes digital collages through social
        networks while exploring his own sense of existence or non-existence. He
        uses networks as a conceptual mirror of his being in which he applies
        the opposite of art, a reality by the second because internet has no
        memory.
      </p>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/m4aXhUkX-bw"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </Layout>
  );
};

export default Chan;
